import React from 'react';
import { Link } from 'react-router-dom';
// import { 
//   Profilepage 
// } from '../../ui-components';
// import { 
//   ProfileCollection 
// } from '../../ui-components';
// import { withAuthenticator } from '@aws-amplify/ui-react';
// import { useBreakpointValue } from '@aws-amplify/ui-react';
// import { Authenticator } from '@aws-amplify/ui-react';
// import { Storage } from "@aws-amplify/storage"

// await Storage.put("test.txt", "Hello");

// await Storage.put('test.txt', 'Protected Content', {
//     level: 'protected',
//     contentType: 'text/plain'
// });


const Profiles = () => {
  // const variant = useBreakpointValue({
  //   small: 'small',
  //   medium: 'default',
  // });
// const ProfileCollectionOverriders = {
//   button: {
//   onClick: () => {
//     <Profilepage />
//   }
//   }
// }
  return (
<section className="profilecontainer">
      {/* <h1 className="large text-primary">Artists</h1> */}
      <p className="leadprofiles">
        <i className="fab fa-connectdevelop"></i> Browse and connect with fans all across the globe
      </p>
      <div className="profiles">
        <div className="profile bg-light">
          <img
            className="round-img"
            src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200"
            alt=""
          />
          <div>
            <h2>John Doe</h2>
            <p>Developer at Microsoft</p>
            <p>Seattle, WA</p>
            <Link to="/profile" className="btn btn-primary">View Profile</Link>
          </div>

          <ul>
            <li className="text-primary">
              <i className="fas fa-check"></i> HTML
            </li>
            <li className="text-primary">
              <i className="fas fa-check"></i> CSS
            </li>
            <li className="text-primary">
              <i className="fas fa-check"></i> JavaScript
            </li>
            <li className="text-primary">
              <i className="fas fa-check"></i> Python
            </li>
            <li className="text-primary">
              <i className="fas fa-check"></i> C#
            </li>
          </ul>
        </div>

        <div className="profile bg-light">
          <img
            className="round-img"
            src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200"
            alt=""
          />
          <div>
            <h2>John Doe</h2>
            <p>Developer at Microsoft</p>
            <p>Seattle, WA</p>
            <Link to='/profile.html' className="btn btn-primary">View Profile</Link>
          </div>

          <ul>
            <li className="text-primary">
              <i className="fas fa-check"></i> HTML
            </li>
            <li className="text-primary">
              <i className="fas fa-check"></i> CSS
            </li>
            <li className="text-primary">
              <i className="fas fa-check"></i> JavaScript
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}

export default (Profiles);